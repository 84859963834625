import FieldPhone from "./FieldPhone.js";
import FieldText from "./FieldText.js";
import Alert from "./Alert.js";

export default class UserLogin {

	setHidden(stage) {
		if (+stage===1){
			this.wrappers[`step2`].hidden = true;
			this.wrappers[`step1`].hidden = false;
			this.buttonSubmitNode.innerText='Войти'
		}else{
			this.wrappers[`step2`].hidden = false;
			this.wrappers[`step1`].hidden = true;
			this.buttonSubmitNode.innerText='Подтвердить'
		}
	}

	constructor() {
		this.stage = localStorage.getItem('stage') ?? 1;
		this.modalLoginNode = document.querySelector('#modal-login')
		this.formNode = this.modalLoginNode.querySelector('form')
		this.buttonSubmitNode = this.formNode.querySelector('[type=submit]')
		this.inputs = {};
		this.submitNode = this.modalLoginNode.querySelector('button[type=submit]');
		this.phoneNode = this.modalLoginNode.querySelector('input[name=phone]');
		this.codeNode = this.modalLoginNode.querySelector('input[name=code]');
		this.handlerEventSubmit = this.eventSubmit.bind(this);
		this.wrappers = {};
		this.wrappers['step1'] = this.modalLoginNode.querySelector('.modal__login-form_step1');
		this.wrappers['step2'] = this.modalLoginNode.querySelector('.modal__login-form_step2');
		this.inputs['phone'] = new FieldPhone(this.phoneNode);
		this.inputs['code'] = new FieldText(this.codeNode);

		if(this.wrappers['step1'] && this.wrappers['step2']){
			this.setHidden(this.stage)
		}
		this.formNode.addEventListener('submit', this.handlerEventSubmit);
	}

	valid() {
		return ! this.error.find((item) => {
			return item;
		})
	}

	async eventSubmit(e) {
		e.preventDefault();
		const body = new FormData();
		this.error = [];
		switch (this.stage) {
			case 1:
				this.error = [];
				this.error.push(this.inputs['phone'].setValid());
				if (this.valid()) {
					body.append('phone', this.inputs['phone'].input.value)
					let response = await fetch('/user/login', {
						method: 'POST',
						body
					})
					response = await response.json()
					if (response?.status === 'success') {
						this.stage = +response.step
						this.setHidden(this.stage)
					}
				}
				break;
			case 2:
				console.log(5555)
				this.error = [];
				this.error.push(this.inputs['phone'].setValid());
				this.error.push(this.inputs['code'].setValid());
				if (this.valid()) {
					body.append('code', this.inputs['code'].input.value)
					body.append('phone', this.inputs['phone'].input.value)
					let response = await fetch('/user/code', {
						method: 'POST',
						body
					})
					response = await response.json()
					if (response?.status === 'success') {
						if (response?.redirect) {
							window.location = response?.redirect
						} else {
							window.location = window.location
						}
					}
					if (response.status === 'error') {
						for(const key in response.errors){
							if (this.inputs[key]){
								this.inputs[key].setError(response.errors[key])
							}else{
								Alert.add( response.errors[key])
							}
						}
					}

				}
				break;

		}
	}

	static init() {
		if (!document.querySelector('#modal-login')) return
		new UserLogin();
	}

}
UserLogin.init();
