import Swiper, { Navigation, Pagination } from 'swiper';

const product = document.querySelector('.product');

export const initProductPreview = () => {
	if (product) {
		let isMobile = true;

		const contentResize = () => {
			if (window.matchMedia('(min-width: 1024px)').matches) {
				if (isMobile) {
					const contentMobile = document.querySelector('.product-preview__another');
					const contentDesktop = document.querySelector('.product-another');
					contentDesktop.innerHTML = contentMobile.innerHTML;
					contentMobile.innerHTML = '';
					isMobile = false;

					const swiper = product.querySelector('.swiper');
					const buttons = product.querySelectorAll('.swiper-button');
					const pagination = product.querySelector('.swiper-pagination');

					new Swiper(swiper, {
						modules: [Navigation, Pagination],
						pagination: {
							el: pagination,
							type: 'fraction',
						},
						navigation: {
							prevEl: buttons[0],
							nextEl: buttons[1],
						},
						speed: 300,
						slidesPerView: 4,
						slidesPerGroup: 4,
						spaceBetween: 24,
					});
				}
			} else {
				if (!isMobile) {
					const contentMobile = document.querySelector('.product-preview__another');
					const contentDesktop = document.querySelector('.product-another');
					contentMobile.innerHTML = contentDesktop.innerHTML;
					contentDesktop.innerHTML = '';
					isMobile = true;

					const swiper = product.querySelector('.swiper');
					const buttons = product.querySelectorAll('.swiper-button');
					const pagination = product.querySelector('.swiper-pagination');


					new Swiper(swiper, {
						modules: [Navigation, Pagination],
						pagination: {
							el: pagination,
							type: 'fraction',
						},
						navigation: {
							prevEl: buttons[0],
							nextEl: buttons[1],
						},
						speed: 300,
						slidesPerView: 2,
						slidesPerGroup: 2,
						spaceBetween: 10,
					});
				}
			}
		};

		const showingMoreBtn =  ()=>{
			const wrapContent = document.querySelector('.product-preview__desc')
			const content  =  document.querySelector('.product-preview__desc .product-preview__desc__content')

			if (wrapContent&&content){
				const maxHeight = parseInt(window.getComputedStyle(wrapContent).maxHeight);
				const realHeight  = content.clientHeight;
				document.querySelector('.product-preview__desc-more').hidden = realHeight<=maxHeight
			}

		}

		contentResize();
		showingMoreBtn();
		window.addEventListener('resize', contentResize);
		window.addEventListener('resize', showingMoreBtn);


		document.addEventListener('click', (e) => {
			if (e.target.classList.contains('product-preview__desc-more')) {
				if (e.target.getAttribute('aria-expanded') === 'false') {
					// e.target.previousElementSibling.style.display = 'grid';
					e.target.setAttribute('aria-expanded', 'true');
					e.target.textContent = 'Меньше';
				} else {
					// e.target.previousElementSibling.style.display = '-webkit-box';
					e.target.setAttribute('aria-expanded', 'false');
					e.target.textContent = 'Больше';
				}
			}

			if (e.target.classList.contains('product-preview__color')) {
				const prev = document.querySelector('.product-preview__color[aria-expanded="true"');

				if (prev) {
					prev.setAttribute('aria-expanded', 'false');
				}

				e.target.setAttribute('aria-expanded', 'true');
			}
		});
	}
};
