let filters;

export default class Filter {
	constructor(form) {
		this.form = form;
		const inputs = form.querySelectorAll(`input`);
		this.resetButton = document.querySelector('.catalog__reset')
		this.headingCount = document.querySelector('.modal__content .modal__heading span')
		this.submit = document.querySelector('.modal__content button[type=submit]')
		const buttons = document.querySelectorAll('[data-filter-clear-name]')
		this.buttons = Array.from(buttons) ?? [];
		this.form?.addEventListener('submit', this.handleSubmitEvent);
		this.resetButton?.addEventListener('click', this.resetFilter.bind(this));

		this.inputs = Array.from(inputs) ?? [];
		this.inputs.map(item => {
			item.addEventListener('change', this.handleChangeInput);
		});
		this.buttons.map(item => {
			item.addEventListener('click', this.handleClickBtns);
		});
		this.count = this.setCount();
	}

	setCount() {
		return this.inputs.filter((item) => {
			return item.checked;
		}).length;
	}

	resetFilter() {
		location = this.clearUrl();
	}

	clearUrl($valueParam = null) {
		const url = new URL(window.location);
		const entries  = Array.from( url.searchParams.entries());
		if (!$valueParam){
			for (const [key, value] of entries) {
				if(key.includes('FilterForm')){
					url.searchParams.delete(key,value);
				}
			}
		}else{
			for (const [key, value] of entries) {
				if(key.includes('FilterForm')){
					if ('price' === $valueParam){
						if (key.includes('price')){
							url.searchParams.delete(key,value);
						}
					}else{
						if ($valueParam === value){
							url.searchParams.delete(key,value);
						}
					}

				}
			}
		}
		// url.searchParams = searchParam;
		// for (const [key, value] of url.searchParams.entries()) {
		// 	url.searchParams.delete(key,value);
		// }


		return url.toString();
	}


	submitEvent(e) {
		e.preventDefault();
		const url = new URL(this.clearUrl());
		this.inputs.map(item => {
			if (item.checked)
				url.searchParams.append(item.name, item.value);
		})
		url.searchParams.append('FilterForm[price_from]', document.querySelector('[name ="FilterForm[price_from]"]').value);
		url.searchParams.append('FilterForm[price_to]', document.querySelector('[name ="FilterForm[price_to]"]').value);
		window.location = url.toString();
	}

	handleSubmitEvent = this.submitEvent.bind(this)
	handleClickBtns = this.clickBtnsEvent.bind(this)

	clickBtnsEvent(e) {

		const filterId = e.currentTarget.dataset.filterClearName;
		if (filterId === 'price') {
			const url = this.clearUrl('price')
			window.location = url
		} else {
			const param = filterId.split('-')
			const url = this.clearUrl( param[1]);
			window.location = url;
		}


		// if (filterId) {
		// 	const input = this.inputs.find(item => {
		// 		return item.id === filterId;
		// 	})
		// 	input.checked = false;
		//
		// 	this.form.dispatchEvent(new Event('submit'))
		// }
	}

	handleChangeInput = this.inputChange.bind(this)

	inputChange(ev) {
		this.count = this.setCount()
		this.headingCount.innerHTML = this.count
		this.submit.querySelector('span').innerHTML = this.count
	}

	static init() {
		let filter = document.querySelector('.modal-filters');
		if (filter) {
			filters = new Filter(filter)
		}
	}
}
Filter.init();
