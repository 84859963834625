import Cookie from './Cookie.js';
class CookieAccept{
	constructor(cookie) {
		this.cookie = cookie;
		this.accept_button = this.cookie.querySelector('.cookie__close');
		this.accept_button.addEventListener('click', this.accept.bind(this));
		this.check();
	}

	check() {
		if (Cookie.get('cookie_accept')) {
			this.cookie.remove();
		} else {
			this.cookie.hidden = false;
		}
	}

	accept() {
		Cookie.set('cookie_accept', true, {
			expires: 365
		});
		this.check();
	}

	static init() {
		const cookie = document.querySelector('.cookie');
		if (cookie) {
			new CookieAccept(cookie);
		}
	}
}
CookieAccept.init();
