export const initModalAnother = () => {
	document.addEventListener('click', (e) => {
		if (e.target.classList.contains('home-preview__menu-open')) {
			document.querySelector('.header__menu-open').click();
		}

		if (e.target.classList.contains('home-preview__search-open')) {
			document.querySelector('.header__search-open').click();
		}

		if (e.target.classList.contains('home-preview__login-open')&&e.target.hasAttribute('data-open-login')) {
			document.querySelector('.header__login-open').click();
		}

		if (e.target.classList.contains('home-preview__cart-open')) {
			document.querySelector('.header__cart-open').click();
		}

		// if (e.target.classList.contains('footer-sticky__add')) {
		// 	document.querySelector('.product-preview__add-cart').click();
		// }

		if (e.target.classList.contains('modal-header__login-open')) {
			document.querySelector('.modal.open').click();
			document.querySelector('.header__login-open').click();
		}

		if (e.target.classList.contains('header__filters-open')) {
			document.querySelector('.catalog__filters-open').click();
		}

		if (e.target.classList.contains('modal__header-search')) {
			document.querySelector('.modal.open').click();
			document.querySelector('.header__search-open').click();
		}
	});
};
