const filterMin = document.querySelector('#filter-min');
const filterMax = document.querySelector('#filter-max');

export const initResizeNumber = () => {
	if (filterMin && filterMax) {

		filterMin.style.width = 0;
		filterMin.style.width = `${filterMin.scrollWidth}px`;

		filterMax.style.width = 0;
		filterMax.style.width = `${filterMax.scrollWidth}px`;

		filterMin.addEventListener('input', () => {
			filterMin.style.width = 0;
			filterMin.style.width = `${filterMin.scrollWidth}px`;
		});

		filterMax.addEventListener('input', () => {
			filterMax.style.width = 0;
			filterMax.style.width = `${filterMax.scrollWidth}px`;
		});
	}

};
