export const initModalFilters = () => {
	document.addEventListener('click', (e) => {
		if (e.target.classList.contains('modal-filters__button')) {
			if (e.target.getAttribute('aria-expanded') === 'false') {
				e.target.setAttribute('aria-expanded', 'true');
			} else {
				e.target.setAttribute('aria-expanded', 'false');
			}
		}
	});
};
