export default class Cookie{
	static get(name) {
		const matches = document.cookie.match(new RegExp(
			`(?:^|; )${ name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') }=([^;]*)`
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	static set(name, value, props) {
		props = props || {};
		let exp = props.expires;
		if (typeof exp === 'number' && exp) {
			const d = new Date();
			d.setTime(d.getTime() + exp * 60 * 60 * 60 * 24);
			exp = props.expires = d;
		}
		if(exp && exp.toUTCString) { props.expires = exp.toUTCString(); }
		value = encodeURIComponent(value);
		let updatedCookie = `${name }=${ value}`;
		for(const propName in props){
			updatedCookie += `; ${ propName}`;
			const propValue = props[propName];
			if(propValue !== true){ updatedCookie += `=${ propValue}`; }
		}
		document.cookie = updatedCookie;
	}

	static delete(name) {
		Cookie.set(name, null, { expires: -1 });
	}
}
