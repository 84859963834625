import Swiper, {Navigation, Pagination} from 'swiper';

const homeAbout = document.querySelector('.home-about');
const homestages = document.querySelector('.home-stages__swiper');
const homeBestsellers = document.querySelector('.home-bestsellers');
const catalog = document.querySelector('.catalog');
const product = document.querySelector('.product');

export const initSwiper = () => {
	if (homeAbout) {
		const swiper = homeAbout.querySelector('.swiper');

		new Swiper(swiper, {
			speed: 300,
			slidesPerView: 'auto',
			spaceBetween: 16,
			breakpoints: {
				744: {
					spaceBetween: 48,
				},
			},
		});
	}
	if (homestages) {
		const swiper = homestages.querySelector('.swiper');

		new Swiper(swiper, {
			speed: 300,
			slidesPerView: 'auto',
			spaceBetween: 16,
			breakpoints: {
				744: {
					spaceBetween: 48,
				},
			},
		});
	}

	if (catalog) {
		const swiper = catalog.querySelector('.swiper');
		const linksWrapper = document.querySelector('.catalog__links-wrapper');
		const linksList = document.querySelector('.catalog__links-wrap');

		if (linksWrapper && linksList) {

			new Swiper(swiper, {
				speed: 300,
				slidesPerView: 'auto',
				spaceBetween: 16,
				breakpoints: {
					744: {
						spaceBetween: 20,
					},
				},
			});


			if (linksWrapper.clientWidth <= linksList.clientWidth - 16) {
				linksWrapper.classList.add('catalog__links-wrapper--arrow');
			} else {
				linksWrapper.classList.remove('catalog__links-wrapper--arrow');
			}
		}
	}

	if (homeBestsellers) {
		const swiper = homeBestsellers.querySelector('.swiper');
		const buttons = homeBestsellers.querySelectorAll('.swiper-button');
		const pagination = homeBestsellers.querySelector('.swiper-pagination');

		new Swiper(swiper, {
			modules: [Navigation, Pagination],
			speed: 300,
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween: 16,
			pagination: {
				el: pagination,
				type: 'fraction',
			},
			navigation: {
				prevEl: buttons[0],
				nextEl: buttons[1],
			},
			breakpoints: {
				744: {
					spaceBetween: 20,
				},
				1024: {
					slidesPerView: 4,
					slidesPerGroup: 4,
				},
			},
		});
	}

	if (product) {
		const swiper = product.querySelector('.swiper');
		const buttons = product.querySelectorAll('.swiper-button');
		const pagination = product.querySelector('.swiper-pagination');

		new Swiper(swiper, {
			modules: [Navigation, Pagination],
			pagination: {
				el: pagination,
				type: 'fraction',
			},
			navigation: {
				prevEl: buttons[0],
				nextEl: buttons[1],
			},
			speed: 300,
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 10,
		});
	}
};
