const header = document.querySelector('.header');
const typicalAlternate = document.querySelector('.typical__preview--alternate');

let isScroll = false;


const changeHeaderScroll = () => {
	if (window.pageYOffset > 5) {
		if (!isScroll) {
			header.classList.add('header--scroll');
			isScroll = true;
		}
	} else {
		if (isScroll) {
			header.classList.remove('header--scroll');
			isScroll = false;
		}
	}
};

export const initHeaderScroll = () => {
	changeHeaderScroll();
	document.addEventListener('scroll', changeHeaderScroll);

	if (typicalAlternate) {
		const crumbs = document.querySelector('.crumbs');
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					header.classList.remove('header--alternate');
					crumbs.classList.remove('crumbs--alternate');
				} else {
					header.classList.add('header--alternate');
					crumbs.classList.add('crumbs--alternate');
				}
			});
		}, {
			threshold: 0,
		});

		document.addEventListener('scroll', () => {
			observer.observe(typicalAlternate.querySelector('.typical__image'));
		});
	}
};
