const set = document.querySelector('.set');

export const initSet = () => {
	if (set) {
		const setItems = set.querySelectorAll('.set__item');

		setItems.forEach((item) => {
			const subitems = item.querySelectorAll('.set__subitem');
			const more = item.querySelector('.set__more');

			subitems.forEach((subitem, index) => {
				if (index <= 2) {
					subitem.classList.add('set__subitem--visible');
				}
			});

			if (subitems.length > 3) {
				more.classList.add('set__more--visible');
			}
		});

		document.addEventListener('click', (e) => {
			if (e.target.classList.contains('set__more')) {
				e.target.classList.remove('set__more--visible');

				const items = e.target.previousElementSibling.querySelectorAll('.set__subitem');

				items.forEach((item) => {
					item.classList.add('set__subitem--visible');
				});
			}
		});
	}
};
