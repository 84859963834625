export const suggestionInputs = {};
export default class Suggestion {
	static  _token = 'cabc149775ff3fa0e98fe385e195655585e0ed8b';
	static  _secret = '116e161496bcd90ed2eadd075b9dca77615e990a';
	static  _url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
	static city = null;
	static street = null;
	static house = null;
	static address = null;

	constructor(input) {
		this.selectedValue = null;
		this.delay = null;
		this.input = input;
		this.wrapper = input.closest('[data-suggestion-wrapper]');
		this.listWrapper = this.wrapper.querySelector('[data-suggestion-listWrapper]');
		this.list = this.wrapper.querySelector('[data-suggestion-list]');
		this.target = input.dataset.suggestion;
		this.handleInputEvent = this.inputEvent.bind(this)
		this.flagOutsideEvent = null
		this.handleFocusEvent = this.focusEvent.bind(this)
		this.input.addEventListener('input', this.handleInputEvent)
		this.input.addEventListener('focus', this.handleFocusEvent)
		this.handleSelectButton = this.selectButton.bind(this)
		this.list.addEventListener('click', this.handleSelectButton)
		this.event  = new Event('customChange')
		this.form = this.input.closest('form');

	}

	focusEvent(e) {
		this.openList()
	}

	inputEvent(e) {

		clearTimeout(this.delay);
		this.delay = setTimeout(async () => {
			let data = null
			switch (this.target) {
				case 'city':
					data = await Suggestion.getCity(e.target.value)
					break;
				case 'street':
					data = await Suggestion.getStreet(e.target.value)
					break;
				case 'house':
					break;
			}
			if (data && data.length) {
				const html = [];
				data.map((item) => {
					html.push(`<li class="order-form__item"><button data-suggest-info='${JSON.stringify(item)}' class="order-form__button" type="button"><span>${item.value}</span></button></li>`);
				})
				this.list.innerHTML = ''
				this.list.innerHTML = html.join(' ');
				this.openList();

			}
		}, 400)
	}
	closeList() {
		this.listWrapper.classList.remove('order-form__choices--visible');
		this.unSetOutsideClick()
	}
	openList() {
		if (this.list.innerHTML !== '') {
			this.listWrapper.classList.add('order-form__choices--visible');
			this.setOutsideClick()
		}
	}

	selectButton(e) {
		if (e.target.classList.contains('order-form__button')) {
			const prevTarget = this.list.querySelector('[aria-expanded="true"]');
			if (prevTarget) {
				prevTarget.setAttribute('aria-expanded', 'false');
			}
			e.target.setAttribute('aria-expanded', 'true');
			let dataInfo = JSON.parse(e.target.dataset.suggestInfo)
			this.input.value = dataInfo.value
			this.selectedValue = dataInfo;
			this.closeList();
			this.form.dispatchEvent(this.event)

		}
	}
	outsideClick = (e) => {
		if (!(this.list.contains(e.target) || e.target.closest('.order-form__button') || e.target === this.input)) {
			// зарываем окно
			this.input.value = ''
			this.selectedValue = null;
			this.closeList();
			this.form.dispatchEvent(this.event)
		}
	}


	setOutsideClick = () => {
		document.addEventListener('click', this.outsideClick, true)
	}
	unSetOutsideClick = () => {
		document.removeEventListener('click', this.outsideClick, true)
	}

	static async _request(body) {
		const headers = {
			"Content-Type": "application/json",
			"Accept": "application/json",
			"Authorization": `Token ${this._token}`
		}
		const options = {
			method: "POST",
			mode: "cors",
			headers,
			body: JSON.stringify(body)
		}

		let response = await fetch(this._url, options)
		response = await response.json();
		return response?.suggestions;
	}

	static async getCity(q) {

		const body = {
			'count': 5,
			'query': `Россия, ${q}`,
			'from_bound': {"value": "city"},
			'to_bound': {"value": "city"},
		}
		return await this._request(body)
	}

	static async getStreet(q) {


		const body = {
			'count': 10,
			'query':q,
			'from_bound': {"value": "street"},
			"locations": [{ "region": "москва" }],
			'to_bound': {"value": "street"},
		}
		const cityString = suggestionInputs.city.selectedValue?.data??null

		if (cityString){
			body['locations']=[
				{
					"kladr_id": cityString.city_kladr_id
				}
			]
			body['restrict_value']=true;
		}

		return await this._request(body)

	}

	static async getHouse(q) {
		const body = {
			'count': 10,
			'query': q,
			'from_bound': {"value": "house"},
			'to_bound': {"value": "house"},
		}
		return await this._request(body)
	}


	static init() {
		const inputs = document.querySelectorAll('[data-suggestion]');
		inputs.forEach((item) => {
			suggestionInputs[item.dataset.suggestion] = new Suggestion(item);
		})

	}

}

Suggestion.init();
