export const initModalHeader = () => {
	document.addEventListener('click', (e) => {
		if (e.target.classList.contains('modal-header__button')) {
			if (e.target.getAttribute('aria-expanded') === 'false') {
				e.target.setAttribute('aria-expanded', 'true');
			} else {
				e.target.setAttribute('aria-expanded', 'false');
			}
		}

		// if (e.target.classList.contains('modal-header__category')) {
		// 	if (e.target.getAttribute('aria-expanded') === 'false') {
		// 		e.target.setAttribute('aria-expanded', 'true');
		// 	} else {
		// 		e.target.setAttribute('aria-expanded', 'false');
		// 	}
		// }
	});
};
