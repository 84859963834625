const buttonList = [];
class ModalMenuTab{
	constructor(target) {
		this.btn = target
		this.id = this.btn.dataset.tabId;
		this.target = document.querySelector(`[data-tab-target-id="${this.id}"]`)
		this.prefix = this.id.split('|')[0]
		this.allTargets = document.querySelectorAll(`[data-tab-target-id^="${this.prefix}"]`)
		this.btn.addEventListener('click',this.handlerClick)


	}
	handlerClick = (e)=>{
		buttonList.forEach(item=>{
			item.setAriaExpanded('false')
		})
		this.allTargets.forEach(item=>{


			item.classList.remove('open')
		})

		this.target.classList.add('open')
		this.setAriaExpanded('true')

	}
	 setAriaExpanded(val = 'false'){
		 this.btn.setAttribute('aria-expanded',val)
	 }
	static init(){
		const buttons = document.querySelectorAll('[data-tab-id]');
		if (buttons){
			buttons.forEach((item)=>{
				buttonList.push(new ModalMenuTab(item))
			})
		}
	}


}

ModalMenuTab.init()
