const typical = document.querySelector('.typical__content');
export const initTypicalContent = () => {
	if (typical) {
		try {
			const textTags = typical.querySelectorAll('p');
			if (textTags.length > 0) {
				textTags.forEach((text) => {
					if (
						text.nextElementSibling.tagName === 'UL' ||
						text.nextElementSibling.tagName === 'OL'
					) {
						text.classList.add('list-caption');
					}
				});
			}
		}catch (e){
			console.log(e);
		}

	}
};
