export const initCatalogField = () => {
	let isOpen = false;

	document.addEventListener('click', (e) => {
		if (e.target.classList.contains('catalog__type-open')) {
			if (e.target.getAttribute('aria-expanded') === 'false') {
				e.target.setAttribute('aria-expanded', 'true');
				document.querySelector('.catalog__type-wrap').classList.add('catalog__type-wrap--visible');
				isOpen = true;
			} else {
				e.target.setAttribute('aria-expanded', 'false');
				document.querySelector('.catalog__type-wrap').classList.remove('catalog__type-wrap--visible');
				isOpen = false;
			}
		}

		if (e.target.classList.contains('catalog__type-button')) {
			const prev = document.querySelector('.catalog__type-button[aria-expanded="true"]');

			if (prev && e.target !== prev) {
				prev.setAttribute('aria-expanded', 'false');
				e.target.setAttribute('aria-expanded', 'true');
				document.querySelector('.catalog__type-open').querySelector('span').textContent = e.target.querySelector('span').textContent;
			}
		}

		if (isOpen && !e.target.closest('.catalog__type')) {
			isOpen = false;
			document.querySelector('.catalog__type-open').setAttribute('aria-expanded', 'false');
			document.querySelector('.catalog__type-wrap--visible').classList.remove('catalog__type-wrap--visible');
		}
	});
};
