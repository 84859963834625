export default class Product {

	constructor() {
		this.cartModalNode = document.querySelector('#modal-cart');
		document.addEventListener('click', this.handlerDocumentClick)

		const productWrapperInfo = document.querySelector('.product-preview__wrapper')
		if (productWrapperInfo) {
			const  productWrapperInfoHeader = productWrapperInfo.querySelector('.product-preview__top-wrap')
			let mql = window.matchMedia("(min-width: 744px)");
			const callback = (entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.querySelector('.product-preview__top-wrap').classList.remove('fixed')
					} else {
						entry.target.querySelector('.product-preview__top-wrap').classList.add('fixed')
					}
				})
			}
			let options;
			const productWrapperInfoHeaderHeight = productWrapperInfoHeader.clientHeight

			if (mql.matches) {
				options = {
					// root: по умолчанию window, но можно задать любой элемент-контейнер
					rootMargin: `0px 0px ${-productWrapperInfoHeaderHeight-95}px 0px`,
					threshold: 0,
				}
			} else {

				options = {
					// root: по умолчанию window, но можно задать любой элемент-контейнер
					rootMargin: `0px 0px ${-productWrapperInfoHeaderHeight-56}px 0px`,
					threshold: 0,
				}
			}
			const observer = new IntersectionObserver(callback, options)
			observer.observe(productWrapperInfo)
		}

	}

	handlerDocumentClick = this.documentClick.bind(this)

	async documentClick(e) {
		const dataProductId = e.target.dataset.productId ?? null;

		if (dataProductId) {
			e.target.disabled = true;
			const body = new FormData();
			body.append('id', dataProductId)
				const sizeNode = document.querySelector('[name="option[size]"]:checked');
			if (sizeNode) {
				console.log()
				body.append('option[size][title]', 'Размер')
				body.append('option[size][value]', sizeNode.value)
			}
			let response = await fetch('/cart/add', {
				method: 'POST',
				body
			})
			window.dataLayer.push({
				"ecommerce": {
					"currencyCode": "RUB",
					"add": {
						"products": [
							window.productInfo
						]
					}
				}
			});

			response = await response.json()
			e.target.disabled = false;
			if (response.html) {
				this.cartModalNode.innerHTML = response.html
			}
		}
		const cartIncPosition = e.target.dataset.cartInc ?? null;
		const cartDecPosition = e.target.dataset.cartDec ?? null;

		if (cartIncPosition) {
			const dataInfo =  JSON.parse(e.target.closest('.stepper').querySelector('.stepper__count').dataset.jsonCart)
			window.dataLayer.push({
				"ecommerce": {
					"currencyCode": "RUB",
					"add": {
						"products": [
							dataInfo
						]
					}
				}
			});

			await this.cartUpdate(e.target, 'inc', cartIncPosition)
		}

		if (cartDecPosition) {
			const dataInfo =  JSON.parse(e.target.closest('.stepper').querySelector('.stepper__count').dataset.jsonCart)
			window.dataLayer.push({
				"ecommerce": {
					"currencyCode": "RUB",
					"remove": {
						"products": [
								dataInfo
						]
					}
				}
			});
			await this.cartUpdate(e.target, 'dec', cartDecPosition)
		}

	}

	async cartUpdate(target, event, position) {
		window.updateCart = true;

		const body = new FormData();
		target.disabled = true;
		body.append('position', position)
		const request = await fetch(`/cart/${event}`, {
			method: 'POST',
			body
		})
		target.disabled = false
		const response = await request.json()
		if (response.html) {
			this.cartModalNode.innerHTML = response.html
			document.querySelector('.footer-sticky__cart span').innerText = response.total
			document.querySelector('.home-preview__cart-open span').innerText = response.total
			document.querySelector('.header__cart-open span').innerText = response.total
		}
	}

	static init() {


		new Product();

	}

}
Product.init();
