import Filed from "./Field.js";

export default class FieldEmail extends Filed{
	setValid(){
		let error = 0;
		if (this.input.value.trim() === '') {
			this.setError('Обязательное поле')
			error = 1
		}
		return error
	}
}
