let more = null;
export default class AddMore {

	constructor(target) {


		this.target = target;
		this._paginationString = 'Pagination_1';
		this.nodePagiantion = document.querySelector('.catalog__pagination');
		this.nodeCatalog = document.querySelector('.catalog__list');
		this.lastPage = parseInt(window.pageData?.lastPage??1);
		this.currentPage = this.setPagination()
		this.click_hendler = this.getData.bind(this)
		this.templateDiv = document.createElement('div');
		this.target.addEventListener('click', this.click_hendler)
	}

	async getData() {


		if (+this.currentPage < +this.lastPage) {
			const url = new URL(window.location);
			const searchParams = new URLSearchParams(url.searchParams)
			const newCurrentPage = parseInt(this.currentPage) + 1;
			searchParams.set(this._paginationString, newCurrentPage);


			for (let [name, value] of searchParams) {
				url.searchParams.set(name, value);
			}
			switch (true) {
				case  url.pathname.includes('/catalog'):
					url.pathname = url.pathname.replace('/catalog', '/catalog/getMore')
					break;
				case  url.pathname.includes('/sale'):
					url.pathname = url.pathname.replace('/sale', '/sale/getMore')
					break;
			}
			this.target.disabled=true;
			const newURL =url.toString();
			let result = await fetch(newURL)
			result = await result.json();
			if (!result.errors){
				this.nodePagiantion.innerHTML = result.paginationList + result.countOfTotal;
				this.currentPage = newCurrentPage;
				result.products.map((item)=>{
					this.nodeCatalog.innerHTML+=item;
				})
				if (result.isLastPage) {
					this.target.classList.add('catalog__more--inActive');
				}
				// window.history.pushState(
				// 	{},
				// 	'',
				// 	newURL.str
				//
				// )
				this.target.disabled=false;
			}

		}

	}
	createNodeElem($string){
		this.templateDiv.innerHTML = $string
		return this.templateDiv.childNodes;
	}

	setPagination() {
		if (!this.currentPage) {
			const url = new URL(window.location)
			let page = url.searchParams.get(this._paginationString);
			if (page) {
				return parseInt(page);
			} else {
				return 1;
			}
		}
	}


	static init() {
		more = document.querySelector('.catalog__more')
		if (more) {
			new AddMore(more)
		}
	}

}
AddMore.init();
