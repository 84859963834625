import { isEscEvent } from './util.js';

const modals = document.querySelectorAll('.modal');

let isOpen = false;

modals.forEach((modal) => (modal.inert = true));

const openModal = (targetData) => {
	window.updateCart = null;
	const modal = document.querySelector(`#${targetData}`);

	if (modal) {
		isOpen = true;

		const content = modal.querySelector('.modal__content');
		const header = modal.querySelector('.modal__header');

		setTimeout(() => {
			modal.inert = false;
			modal.classList.add('open');
		}, 100);

		if (content) {
			setTimeout(() => {
				content.classList.add('modal__content--visible');
			}, 300);
		}

		if (header) {
			setTimeout(() => {
				header.classList.add('modal__header--visible');
			}, 300);
		}

		document.addEventListener('keydown', onModalCloseKeyDown);
	}
};

export const closeModal = () => {
	if (window.updateCart){
		const url = new URL(window.location)
		if (url.pathname==='/order'){
			window.location = location
		}
	}
	const modalOpen = document.querySelector('.modal.open');

	if (isOpen) {
		if (modalOpen) {
			const modalForm = modalOpen.querySelector('form');
			const modalFields = modalOpen.querySelectorAll('.field');
			const form = document.querySelector('.form.send');

			const content = modalOpen.querySelector('.modal__content');
			const header = modalOpen.querySelector('.modal__header');

			if (content) {
				content.classList.remove('modal__content--visible');
			}

			if (header) {
				header.classList.remove('modal__header--visible');
			}

			setTimeout(() => {
				modalOpen.inert = true;
				modalOpen.classList.remove('open');
			}, 300);

			document.removeEventListener('keydown', onModalCloseKeyDown);

			isOpen = false;

			if (modalForm) {
				modalForm.reset();
				modalFields.forEach((field) => {
					field.classList.remove('field--error');
					field.classList.remove('field--value');
					field.classList.remove('input');
				});
			}

			if (form) {
				form.classList.remove('send');
			}
		}
	}

};

function onModalCloseKeyDown(e) {
	if (isEscEvent(e)) {
		closeModal();
	}
}

export const openModalSuccess = () => {
	const modalOpen = document.querySelector('.modal.open');
	const formSend = document.querySelector('.form.send');

	if (modalOpen) {
		modalOpen.inert = true;

		setTimeout(() => {
			modalOpen.classList.remove('open');
		}, 300);
	}

	if (formSend) {
		setTimeout(() => {
			isOpen = true;
		}, 100);
	}
};

const onModalClick = (e) => {
	if (e.target.dataset.modal) {
		e.preventDefault();
		openModal(e.target.dataset.modal);
	}

	if (e.target.closest('.modal-close') || e.target.classList.contains('modal')) {
		e.preventDefault();
		closeModal();
	}
};

export const initModal = () => {
	document.addEventListener('click', onModalClick);
};
