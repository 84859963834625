import Suggestion from './Suggestion.js'
const input = document.querySelectorAll('.field__input[name="locality"]');
export const initOrderChoices = () => {
	if (input) {
		const list = document.querySelector('.order-form__choices');
		const listBox = document.querySelector('order-form__list');

		input.addEventListener('focus', () => {
			list.classList.add('order-form__choices--visible');
		});

		document.addEventListener('click', (e) => {
			if (e.target.classList.contains('order-form__button')) {
				const prevTarget  = document.querySelector('.order-form__button[aria-expanded="true"]');

				if (prevTarget) {
					prevTarget.setAttribute('aria-expanded', 'false');
				}



				e.target.setAttribute('aria-expanded', 'true');
				input.parentElement.classList.remove('field--error');
				input.value = e.target.textContent;
				list.classList.remove('order-form__choices--visible');
			}

			if (!e.target.closest('.order-form__locality')) {
				list.classList.remove('order-form__choices--visible');
			}
		});
		let delay = null;

		input.addEventListener('input', () => {
			const prevTarget = document.querySelector('.order-form__button[aria-expanded="true"]');

			clearTimeout(delay);
			delay = setTimeout(async ()=>{
				const data = await Suggestion.getCity(input.value)


			},400)




			if (prevTarget) {
				prevTarget.setAttribute('aria-expanded', 'false');
			}
		});
	}
};
