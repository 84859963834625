const modalSearch = document.querySelector('.modal__field-search');

export const initFieldSearch = () => {
	if (modalSearch) {
		const input = modalSearch.querySelector('.field__input');
		const submit = modalSearch.querySelector('.field__search-submit');
		const reset = modalSearch.querySelector('.field__search-reset');

		input.addEventListener('input', () => {
			if (input.value !== '') {
				submit.classList.add('field__search-submit--visible');
				reset.classList.add('field__search-reset--visible');
			} else {
				submit.classList.remove('field__search-submit--visible');
				reset.classList.remove('field__search-reset--visible');
			}
		});

		reset.addEventListener('click', () => {
			submit.classList.remove('field__search-submit--visible');
			reset.classList.remove('field__search-reset--visible');
		});
	}
};
