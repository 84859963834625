class Partners {
	constructor(partners_section) {
		this.partners_section = partners_section;
		this.partners = this.partners_section.querySelectorAll('.service-partners__item');
		if(this.partners.length) {
			this.partners.forEach(partner => partner.addEventListener('mouseenter', this.showPreview.bind(this)))
			this.partners.forEach(partner => partner.addEventListener('mouseleave', this.hiddenPreview.bind(this)))
		}
	}
	showPreview(e){
		let partner = e.target;
		partner.querySelector('.service-partners__preview').classList.add('active')
	}

	hiddenPreview(e){
		let partner = e.target;
		partner.querySelector('.service-partners__preview').classList.remove('active')
	}

	reInit() {
		this.partners.forEach(partner => {
			partner.removeEventListener('click', this.showPreview.bind(this))
			partner.removeEventListener('mouseenter', this.showPreview.bind(this))
		})
		Partners.init()
	}

	static init() {
		const partners_section = document.querySelector('.service-partners')
		if(partners_section) {
			new Partners(partners_section);
		}
	}
}
Partners.init();
