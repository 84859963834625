import FieldPhone from "./FieldPhone.js";
import FieldText from "./FieldText.js";
import FieldEmail from "./FieldEmail.js";
import Alert from "./Alert.js";

export default class User {
	setHidden(stage) {
		if (+stage === 1) {
			this.wrappers[`step2`].hidden = true;
			this.wrappers[`step1`].hidden = false;
			this.buttonSubmitNode.innerText = 'Изменить'
		} else {
			this.wrappers[`step2`].hidden = false;
			this.wrappers[`step1`].hidden = true;
			this.buttonSubmitNode.innerText = 'Подтвердить'
		}
	}

	valid() {
		return !this.error.find((item) => {
			return item;
		})
	}

	constructor(accountForm) {
		this.stage = 1;
		this.formNode =accountForm
		this.inputs = {}
		this.wrappers = {}

		this.wrappers['step1'] = this.formNode.querySelector('.account__form_step1');
		this.wrappers['step2'] = this.formNode.querySelector('.account__form_step2')
		this.buttonSubmitNode = this.formNode.querySelector('[type=submit]');
		this.setHidden(this.stage);
		this.inputs['phone'] = new FieldPhone(this.formNode.querySelector('[name=phone]'));
		this.inputs['name'] = new FieldText(this.formNode.querySelector('[name=name]'));
		this.inputs['email'] = new FieldEmail(this.formNode.querySelector('[name=email]'));
		this.inputs['code'] = new FieldText(this.formNode.querySelector('[name=code]'));
		this.handlerEventSubmit = this.eventSubmit.bind(this);
		this.formNode.addEventListener('submit', this.handlerEventSubmit);
	}


	async eventSubmit(e) {
		e.preventDefault();
		if (this.stage === 1) {
			this.error = []
			this.error.push(this.inputs['phone'].setValid())
			this.error.push(this.inputs['name'].setValid())
			this.error.push(this.inputs['email'].setValid())

			if (this.valid()) {
				const body = new FormData();
				body.append('name', this.inputs['name'].input.value)
				body.append('email', this.inputs['email'].input.value)
				body.append('phone', this.inputs['phone'].input.value)
				let response = await fetch('/user/personal/change', {
					method: 'POST',
					body
				})
				response = await response.json();
				if (response.status === 'success') {
					if (response.step) {
						this.stage = response.step
						this.setHidden(this.stage);
					} else {

						Alert.add( 'Данные сохранены')
					}
				}
				if (response.status === 'error') {
					for(const key in response.errors){
						if (this.inputs[key]){
							this.inputs[key].setError(response.errors[key])
						}else{
							Alert.add( response.errors[key])
						}
					}
				}
			}
		} else {
			this.error = []
			this.error.push(this.inputs['phone'].setValid())
			this.error.push(this.inputs['name'].setValid())
			this.error.push(this.inputs['email'].setValid())
			this.error.push(this.inputs['code'].setValid())
			if (this.valid()) {
				const body = new FormData();
				body.append('name', this.inputs['name'].input.value)
				body.append('email', this.inputs['email'].input.value)
				body.append('phone', this.inputs['phone'].input.value)
				body.append('code', this.inputs['code'].input.value)
				let response = await fetch('/user/personal/change/code', {
					method: 'POST',
					body
				})
				response = await response.json()
				if (response.status === 'success') {
					window.location = response.redirect
				}

			}

		}


	}


	static init() {
	let form= document.querySelector('#accountForm')
		if (form){
			new User(form);
		}
	}

}
User.init();
