import 'wicg-inert';
import 'what-input';
import './modules/CookieAccept.js';
import './modules/ModalMenuTab.js';
import { testSupportWebp } from './modules/test-support-webp.js';
import { initLinkScroll } from './modules/init-link-scroll.js';
import { defocusButton } from './modules/defocus-button.js';
import { initPhoneMask } from './modules/init-phone-mask.js';
import { initPhoneHref } from './modules/init-phone-href.js';
import { initHeaderScroll } from './modules/init-header-scroll.js';
import { initSwiper } from './modules/init-swiper.js';
import { initTypicalContent } from './modules/init-typical-content.js';
// import { initOrderDelivery } from './modules/init-order-delivery.js';
import { initOrderChoices } from './modules/init-order-choices.js';
import { initSet } from './modules/init-set.js';
import { initProductPreview } from './modules/init-product-preview.js';
// import { initOrderForm } from './modules/init-order-form.js';
import { initFieldSearch } from './modules/init-field-search.js';
import { initModalHeader } from './modules/init-modal-header.js';
import { initModalFilters } from './modules/init-modal-filters.js';
import { initResizeNumber } from './modules/init-resize-number.js';
import { initNoUiSlider } from './modules/init-nouislider.js';
import { initModal } from './modules/init-modal.js';
import { initModalAnother } from './modules/init-modal-another.js';
import { sendForm } from './modules/send-form.js';
import { initCatalogField } from './modules/init-catalog-field.js';
import addMore from  './modules/AddMore.js';
import Filter from  './modules/Filter.js';
import Product from  './modules/Product.js';
import UserLogin from  './modules/UserLogin.js';
import User from  './modules/User.js';
import Suggestion from  './modules/Suggestion.js';
import Order from  './modules/Order.js';
import Wholesaler from  './modules/Wholesaler.js';
import Alert from  './modules/Alert.js';
import './modules/Partners.js';
document.addEventListener('click',function (e) {
	if (e.target.closest('.card__heading') && e.target.href) {
		e.preventDefault()
		const dataJson =  JSON.parse(e.target.closest('.card').dataset.json);


		window.dataLayer.push({
			"ecommerce": {
				"currencyCode": "RUB",
				"click": {
					"products": [
						dataJson
					]
				}
			}
		});
		location = e.target.href;
	}

})
testSupportWebp();
defocusButton();
initPhoneHref();
initLinkScroll();
initPhoneMask();
initHeaderScroll();
initSwiper();
initTypicalContent();
// initOrderDelivery();
// initOrderChoices();
initSet();
initProductPreview();
// initOrderForm();
initFieldSearch();
initModalHeader();
initModalFilters();
initResizeNumber();
initNoUiSlider();
initModal();
initModalAnother();
// sendForm();
initCatalogField();

function initServicePreview(){
	let preview = document.querySelector('.service-preview__preview');
	let box = document.querySelector('.service-preview__box');
	let info = document.querySelector('.service-preview__info');
	if(preview && box && info){
		// box.style.paddingBottom = preview.offsetHeight - info.offsetHeight - window.getComputedStyle(box).getPropertyValue('padding-top').replace('px', '') + 'px';
		box.style.minHeight = preview.offsetHeight + 'px';
	}
}

if(document.querySelector('.service-preview')){
	initServicePreview();
	window.addEventListener('resize', () => {
		initServicePreview();
	})
}

if(document.querySelector('.service-partners')) {

}
