import * as noUiSlider from 'nouislider/dist/nouislider.min.js';

const catalogFilterSlider = document.querySelector('.modal-filters__slider');

export const initNoUiSlider = () => {
	if (catalogFilterSlider) {
		const min = document.querySelectorAll('.modal-filters__range')[0];
		const max = document.querySelectorAll('.modal-filters__range')[1];

		const range = noUiSlider.create(catalogFilterSlider, {
			start: [min.value, max.value],
			connect: true,
			step: 1,
			range: {
				'min': +min.min,
				'max': +max.max,
			},
			format: {
				to: function (value) {
					if (Number.isInteger(value)) {
						return value.toFixed(0);
					}
					return value.toFixed(0);
				},
				from: function (value) {
					return parseFloat(value);
				},
			},
		});

		let isUpdate = true;
		if (isUpdate) {
			range.on('update', (values) => {
				min.value = `${values[0]}`;
				max.value = `${values[1]}`;
				isUpdate = false;

				min.style.width = 0;
				min.style.width = `${min.scrollWidth}px`;
				max.style.width = 0;
				max.style.width = `${max.scrollWidth}px`;
			});
		}

		range.on('slide', (values) => {
			min.value = `${values[0]}`;
			max.value = `${values[1]}`;
		});

		min.addEventListener('input', () => {
			min.value = `${min.value.replace(/\D/g, '')}`;

			min.style.width = 0;
			min.style.width = `${min.scrollWidth}px`;
		});

		max.addEventListener('input', () => {
			max.value = `${max.value.replace(/\D/g, '')}`;

			max.style.width = 0;
			max.style.width = `${max.scrollWidth}px`;
		});

		min.addEventListener('focus', () => {
			min.value = `${min.value.replace(/\D/g, '')}`;

			min.style.width = 0;
			min.style.width = `${min.scrollWidth}px`;
		});

		max.addEventListener('focus', () => {
			max.value = `${max.value.replace(/\D/g, '')}`;

			max.style.width = 0;
			max.style.width = `${max.scrollWidth}px`;
		});

		min.addEventListener('blur', () => {
			min.value = `${min.value.replace(/\D/g, '')}`;
			range.setHandle(0, `${min.value.replace(/\D/g, '')}`);

			min.style.width = 0;
			min.style.width = `${min.scrollWidth}px`;
		});

		max.addEventListener('blur', () => {
			max.value = `${max.value.replace(/\D/g, '')}`;
			range.setHandle(1, `${max.value.replace(/\D/g, '')}`);

			max.style.width = 0;
			max.style.width = `${max.scrollWidth}px`;
		});

		min.addEventListener('keydown', (evt) => {
			if (evt.key === 'Enter') {
				min.value = `${min.value.replace(/\D/g, '')}`;
				range.setHandle(0, `${min.value.replace(/\D/g, '')}`);

				min.style.width = 0;
				min.style.width = `${min.scrollWidth}px`;

				min.blur();
			}
		});

		max.addEventListener('keydown', (evt) => {
			if (evt.key === 'Enter') {
				max.value = `${max.value.replace(/\D/g, '')}`;
				range.setHandle(1, `${max.value.replace(/\D/g, '')}`);

				max.style.width = 0;
				max.style.width = `${max.scrollWidth}px`;

				max.blur();
			}
		});
	}
};
