export default class Filed {
	constructor(filedNode) {
		try {
			this.parent = filedNode.closest('.field')
			this.input = filedNode;
			this.label = this.parent.querySelector('label') ?? null;
			this.error = this.parent.querySelector('.field__error') ?? null;
			this.handlerEventFocus = this.eventFocus.bind(this)
			this.input.addEventListener('focus',  this.handlerEventFocus)
		} catch (e) {
			console.log(e)
		}
	}
	eventFocus(){
		this.parent.classList.remove('field--error');
		this.error.textContent = ''
	}

	destroy(){
		this.input.removeEventListener('focus',  this.handlerEventFocus)
	}
	setError(textErr) {
		try {
			this.error.textContent = textErr
			this.parent.classList.add('field--error');
		} catch (e) {
			console.log(e)
		}
	}
}
