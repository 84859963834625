import {suggestionInputs} from "./Suggestion.js";
import FieldText from "./FieldText.js";
import FieldPhone from "./FieldPhone.js";

export default class Order {


	/**
	 * Constructor for initializing the form event handlers
	 * @param {HTMLElement} form - The form element to be handled
	 */
	constructor(form) {

		this.form = form;
		this.handleFormChange = this.changeEvent.bind(this);
		this.form.addEventListener('customChange', this.handleFormChange);
		this.handleNativeFormChange = this.nativeFormChangeEvent.bind(this);
		this.handleSubmitEvent = this.submitEvent.bind(this);
		this.form.addEventListener('change', this.handleNativeFormChange);
		this.form.addEventListener('submit', this.handleSubmitEvent);
		this.inputs ={}
		this.initFields();
		this.event = new Event('customChange');
	}
	initFields(){
		if (this.inputs['city']) this.inputs['city'].destroy();
		if (this.inputs['street']) this.inputs['street'].destroy();
		if (this.inputs['house']) this.inputs['house'].destroy();
		if (this.inputs['apartment']) this.inputs['apartment'].destroy();
		if (this.inputs['first_name']) this.inputs['first_name'].destroy();
		if (this.inputs['last_name']) this.inputs['last_name'].destroy();
		if (this.inputs['email']) this.inputs['email'].destroy();
		if (this.inputs['phone']) this.inputs['phone'].destroy();
		if (this.inputs['comment']) this.inputs['comment'].destroy();

		this.inputs['city'] = new FieldText(document.querySelector('input[name="OrderForm[city]"]'));
		this.inputs['street'] = new FieldText(document.querySelector('input[name="OrderForm[street]"]'));
		this.inputs['house'] = new FieldText(document.querySelector('input[name="OrderForm[house]"]'));
		this.inputs['apartment'] = new FieldText(document.querySelector('input[name="OrderForm[apartment]"]'));
		this.inputs['first_name'] = new FieldText(document.querySelector('input[name="OrderForm[first_name]"]'));
		this.inputs['last_name'] = new FieldText(document.querySelector('input[name="OrderForm[last_name]"]'));
		this.inputs['email'] = new FieldText(document.querySelector('input[name="OrderForm[email]"]'));
		this.inputs['phone'] = new FieldPhone(document.querySelector('input[name="OrderForm[phone]"]'));
		this.inputs['comment'] = new FieldText(document.querySelector('input[name="OrderForm[comment]"]'));

		document.querySelectorAll('input[name="OrderForm[delivery_type]"]').forEach((item)=>{
			console.log(item)
			item.addEventListener('change',function (e){
				document.querySelector('.method_delivery_type .field__error').innerText = ''
			})
		})

	}

	async submitEvent(e){
		e.preventDefault();
		const body = this.getFormData();
		let response  = await fetch('/orderSubmit',{
			method:'POST',
			body,
		})
		response = await response.json();
		if (response.status==='error'){
			for(const key in response.errors){
				if (key==='delivery_type'){
					console.log(response.errors[key][0])
					document.querySelector('.method_delivery_type .field__error').innerText=response.errors[key][0]
					document.querySelector('.method_delivery_type .field__error').style.display='block';
				}else{
					this.inputs[key].setError(response.errors[key][0])
				}
			}
		}else if(response.status==='success'){
			let patch = response.redirect.split('/');
			window.dataLayer.push({
				"ecommerce": {
					"currencyCode": "RUB",
					"purchase": {
						"actionField": {
							"id" :patch[patch.length-1]
						},
						"products": [orderItems]
					}
				}
			});
			location = response.redirect
		}


	}
	nativeFormChangeEvent(e){
		if(e.target.name==='OrderForm[delivery_type]'){
			this.form.dispatchEvent(this.event);
			e.target.checked=true;
		}
	}

	getFormData(){
		const form  = document.querySelector('.making-order__form')
		let body = new FormData(form);
		body.append('OrderForm[kladr]', suggestionInputs.city.selectedValue?.data?.city_kladr_id)
		body.append('OrderForm[address]', [
			this.inputs['city'].input.value,
			this.inputs['street'].input.value,
			this.inputs['house'].input.value,
			this.inputs['apartment'].input.value
		].join(', '));
		return body
	}

	async changeEvent(e) {
		const body = this.getFormData()
		let response = await fetch('/orderChange', {
			method: 'POST',
			body
		})
		response = await response.json();


		if (response['delivery_types']){
			this.form.querySelector('.deliveryTypes').innerHTML = response['delivery_types']
		}
		if (response['total']){
			document.querySelector('.order-result .total').innerHTML = response['total']
		}
		this.initFields();
	}


	static init() {
		const form = document.querySelector('.making-order__form');
		if (form) new Order(form)

	}

}

Order.init();
