import Filed from "./Field.js";

export default class FieldPhone extends Filed{
	phoneText = (value) => /^[A-Za-zА-Яа-яЁё]/g.test(value);
	setValid(){
		let error = 0;
		if (/[_]/.test(this.input.value)) {
			this.setError('Некорректный формат')
			error = 1
		} else if (this.phoneText(this.input.value)) {
			this.setError('Некорректный формат')
			error = 1
		} else if (this.input.value.trim() === '') {
			this.setError('Обязательное поле')
			error = 1
		}
		return error
	}
}
