import FieldText from "./FieldText.js";
import FieldPhone from "./FieldPhone.js";

export default class Wholesaler {


	/**
	 * Constructor for initializing the form event handlers
	 * @param {HTMLElement} form - The form element to be handled
	 */
	constructor(form) {

		this.form = form;
		this.handleSubmitEvent = this.submitEvent.bind(this);
		this.form.addEventListener('submit', this.handleSubmitEvent);
		this.inputs ={}
		this.initFields();
	}
	initFields(){
		if (this.inputs['phone']) this.inputs['phone'].destroy();
		if (this.inputs['email']) this.inputs['email'].destroy();
		this.inputs['email'] = new FieldText(document.querySelector('input[name="WholesalerForm[email]"]'));
		this.inputs['phone'] = new FieldPhone(document.querySelector('input[name="WholesalerForm[phone]"]'));

	}
	getFormData(){
		return new FormData(this.form.querySelector('.service-form__form'))
	}
	async submitEvent(e){
		e.preventDefault();
		const body = this.getFormData();
		let response  = await fetch(`${window.location.origin}/wholesalerForm`,{
			method:'POST',
			body,
		})
		response = await response.json();
		console.log(response)
		if (response.state==='error'){
			for(const key in response.errors.WholesalerForm){
				this.inputs[key].setError(response.errors.WholesalerForm[key][0])
			}
		}else if(response.state==='success'){
			let link = document.createElement('a');
			link.setAttribute('href', '/files/offer_copy.pdf');
			link.setAttribute('download', 'offer_copy.pdf');
			link.click();
			return false;


			// let patch = response.redirect.split('/');
			// window.dataLayer.push({
			// 	"ecommerce": {
			// 		"currencyCode": "RUB",
			// 		"purchase": {
			// 			"actionField": {
			// 				"id" :patch[patch.length-1]
			// 			},
			// 			"products": [orderItems]
			// 		}
			// 	}
			// });
			// location = response.redirect
		}


	}



	static init() {
		const form = document.querySelector('#service-form');
		if (form) new Wholesaler(form)

	}

}

Wholesaler.init();
